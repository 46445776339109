import React, { Component } from 'react';
import {
  injectIntl,
  IntlShape
} from 'react-intl';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  ELSTokenHelper,
  ELSURLHelper
} from '@els/els-ui-common-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Dropdown } from '@els/els-react--dropdown';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { locationActions } from '../../redux/location/location.actions';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import StudentStudyHeaderNavComponent from '../../components/student-study-header-nav/StudentStudyHeaderNav.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  fetchEaqAssignment,
  fetchStudentPerformanceDetails
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import * as constants from '../eaq-student-study/eaq-student-study.constants';
import {
  AssessmentDto,
  AssessmentPerformanceDetailsDto,
  AssessmentTypeDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  ELSGaugeChart,
  ELSTab,
  ELSTabGroup
} from '../../components/els.components';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import sassConstants from '../../assets/_constants.scss';
import { StudentStudyDto } from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { DropDownOption } from '../../models/ui.model';
import { fetchAssessmentByAssignmentIdAndUserId } from '../../apis/eols-assessment-service/eols-assessment-service.utilities';
import {
  getFormattedAssessmentGoal,
  getFormattedAssessmentType,
  getFormattedAssignmentGradeType,
  renderFormattedDate
} from '../../utilities/eaq-self-study.utilities';
import { fetchUser } from '../../apis/eols-user-management-service/eols-user-management-service.utilities';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  assessments: studySelectors.getStudentStudyAssessments(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state)
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  returnAppLink: studyActions.returnAppLink,
  fetchStudentPerformanceDetails: studyActions.fetchStudentPerformanceDetailsAction,
  trackAction: studyActions.trackAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type EaqStudentPerformanceDetailsProps = PropsFromRedux & RouteComponentProps & { intl: IntlShape };

interface EaqStudentPerformanceDetailsState {
  assessment: AssessmentDto;
  assessmentType: AssessmentTypeDto;
  assessmentGoal: string;
  assessmentResults: AssessmentPerformanceDetailsDto | null;
  assignment: AssignmentDto;
  studentName: string;
  activeTabIndex: number;
  activeDropdownOption: React.ReactNode;
  dropdownOptions: DropDownOption[];
  assignmentGradeType: string;
  assessmentStartDate: string;
  assessmentCompleteDate: string;
  assignmentDueDate: string;
}

export class EaqStudentPerformanceDetails extends Component<EaqStudentPerformanceDetailsProps, EaqStudentPerformanceDetailsState> {
  constructor(props: EaqStudentPerformanceDetailsProps) {
    super(props);
    this.state = {
      assessment: null,
      assessmentType: null,
      assessmentGoal: '',
      assessmentResults: null,
      assignment: null,
      studentName: '',
      activeTabIndex: 0,
      dropdownOptions: [
        {
          id: 'all',
          label: 'all'
        },
        {
          id: 'incorrect',
          label: 'incorrect'
        },
        {
          id: 'correct',
          label: 'correct'
        }
      ],
      activeDropdownOption: { id: 'all', label: 'all' },
      assignmentGradeType: '',
      assessmentStartDate: '',
      assessmentCompleteDate: '',
      assignmentDueDate: '',
    };
  }

  componentDidMount() {
    const { assessments, location, appLinkData } = this.props;
    const { outPostBody } = appLinkData;
    const eolsUser = ELSTokenHelper.getUser();
    const isFromSSA = assessments.length > 0;

    if (isFromSSA) {
      // handles launch from ssa progress page
      const assessmentId = Number(ELSURLHelper.getParameterByName('assessmentId', location.search));
      this.handleSSALaunch(assessmentId, assessments);
    } else {
      // handles launch from outside of ssa progress page: eaq/cw
      this.handleNonSSALaunch(eolsUser.userId.toString(), outPostBody.assignmentId);
    }

    this.setState({
      studentName: `${eolsUser.firstName} ${eolsUser.lastName}`,
    });
  }

  fetchAssessmentId = async (eolsUserId: string, assignmentId: number) => {
    const assessment = await fetchAssessmentByAssignmentIdAndUserId(eolsUserId, assignmentId);
    this.setState({ assessment });
    return assessment.id;
  }

  handleTabChange = (index: number) => {
    this.setState({
      activeTabIndex: index
    });
  };

  handleReturnNavigation = () => {
    return this.props.returnAppLink({
      linkId: this.getParentLinkId(this.props),
      returnPostBody: null
    });
  };

  getParentLinkId = (props: EaqStudentPerformanceDetailsProps): string => {
    const { appLinkData, appLinkCookies } = props;
    if (appLinkData && appLinkData.parentLinkId) {
      return appLinkData.parentLinkId;
    }
    return appLinkCookies ? appLinkCookies.linkId : null;
  };

  fetchAssessmentResults = (assessmentId: number) => {
    return fetchStudentPerformanceDetails(assessmentId);
  }

  fetchAssignmentDetails = async (assignmentId: number) => {
    const assignment = await fetchEaqAssignment(assignmentId);
    this.setState({ assignment });
  }

  handleSSALaunch = (assessmentId: number, assessments: StudentStudyDto[]) => {
    const filteredAssessment: StudentStudyDto = assessments.find(studentStudyDto => {
      return studentStudyDto.assessment.id === assessmentId;
    });
    const { assessment, assessmentSubmission } = filteredAssessment;

    this.fetchAssessmentResults(assessmentId).then(assessmentResults => {
      this.setState({
        assessmentResults,
        assessmentType: assessment.type,
        assessmentGoal: getFormattedAssessmentGoal(assessment.type, assessment.assessmentGoals[0].goal, this.props.messages),
        assessmentStartDate: assessmentSubmission.createdAt,
        assessmentCompleteDate: assessmentSubmission.createdAt,
      });
    });
  }

  handleNonSSALaunch = (eolsUserId: string, assignmentId: number) => {
    this.fetchAssessmentId(eolsUserId, assignmentId).then(assessmentId => {
      this.fetchAssignmentDetails(assignmentId).then(() => {
        const { assessment, assignment } = this.state;
        this.setState({
          assignmentGradeType: assignment.assignmentGradeType,
          assessmentType: assessment.type,
          assessmentGoal: getFormattedAssessmentGoal(assessment.type, assessment.assessmentGoals[0].goal, this.props.messages),
          assessmentStartDate: assessment.studentAssignment.createdAt,
        });
        this.fetchAssessmentResults(assessmentId).then(assessmentResults => {
          this.setState({
            assessmentResults,
            assessmentCompleteDate: assessmentResults.completedDateTime,
            assignmentDueDate: assessmentResults.dueDateTime
          });
        });
      });
    });
  }

  renderTotalQuestionsText = (assessmentResults: AssessmentPerformanceDetailsDto): JSX.Element => {
    const { intl, messages } = this.props;
    const totalQuestions: number = assessmentResults ? assessmentResults.questions.length : 0;
    const correctTotal: number = assessmentResults ? assessmentResults.totalCorrect : 0;
    const incorrectTotal: number = assessmentResults ? totalQuestions - correctTotal : 0;

    const formattedMessage = intl.formatMessage(
      {
        id: LANGUAGE_KEY.BASED_ON_TOTAL_QUESTIONS,
        defaultMessage: messages.BASED_ON_TOTAL_QUESTIONS
      },
      {
        totalQuestions: <b>{totalQuestions}</b>
      }
    );

    return (
      <div>
        <p>
          {formattedMessage} ({incorrectTotal} {messages.INCORRECT}, {correctTotal} {messages.CORRECT})
        </p>
      </div>
    );
  }

  render() {
    const { messages } = this.props;
    const {
      assessmentResults,
      studentName,
      activeTabIndex,
      assessmentType,
      assessmentGoal,
      assessmentStartDate,
      assessmentCompleteDate,
      assignmentDueDate,
      assignmentGradeType,
      activeDropdownOption,
      dropdownOptions
    } = this.state;

    return (
      <div className="c-ssa-eaq-student-performance-details">
        <StudentStudyHeaderNavComponent
          handleReturnNavigation={this.handleReturnNavigation}
          headerTitle={messages.ELSEVIER_ADAPTIVE_QUIZZING}
          isMenuItemsEnabled={false}
          headerTitleClassName="u-els-color-secondary"
          isSticky
        />

        {assessmentResults && assessmentResults.topics && (
          <div className="c-ssa-eaq-student-performance-details__description-block">

            <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.WRAP_AT_MOBILE]}>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
                  <FlexItem>
                    <strong><h2>{assessmentResults.quizName ? assessmentResults.quizName : assessmentResults.topics[0].text}</h2></strong>
                  </FlexItem>
                </FlexLayout>
                <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.WRAP_AT_MOBILE, FlexLayoutModifier.WRAP_AT_TABLET]}>
                  <FlexItem classes={['u-els-padding-left-3x@mobile u-els-padding-left-3x@tablet']}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.STUDENT}:</h4>
                          </td>
                          <td>
                            <h4>{studentName}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.TYPE}:</h4>
                          </td>
                          <td>
                            <h4>{getFormattedAssessmentType(assessmentType, messages)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.GOAL}:</h4>
                          </td>
                          <td>
                            <h4>{assessmentGoal}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FlexItem>
                  <FlexItem>
                    <table>
                      <tbody>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.DUE}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentResults.dueDateTime)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.START}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentStartDate)}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="u-els-float-right u-els-padding-right-1o2">
                            <h4>{messages.COMPLETE}:</h4>
                          </td>
                          <td>
                            <h4>{renderFormattedDate(assessmentCompleteDate)}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FlexItem>
                </FlexLayout>
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.LEFT]} classes={['u-els-padding-top@mobile']}>
                <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE]}>
                  <FlexItem>
                    <div className="c-ssa-eaq-student-performance-details__gauge-chart">
                      <ELSGaugeChart
                        percent={Math.floor(assessmentResults.percentage * 100)}
                        radius={50}
                        percentColor={sassConstants.elsColorExtendedBlue7}
                        offsetColor={sassConstants.elsColorN1}
                        label={(
                          <>
                            <b>{messages.FINAL_SCORE}</b><br />
                            {getFormattedAssignmentGradeType(assignmentGradeType, assessmentCompleteDate, assignmentDueDate, messages)}
                          </>
                        )}
                        infoClassName="u-els-margin-top-1o2"
                      />
                    </div>
                  </FlexItem>
                </FlexLayout>
              </FlexItem>
            </FlexLayout>
          </div>
        )}
        <div className="c-ssa-eaq-student-performance-details__page-content">
          <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE]}>
            <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
              {assessmentType !== AssessmentTypeDto.QUIZ_BY_QUESTION
                && (
                  <div className="c-ssa-eaq-student-performance-details__tab-group">
                    <ELSTabGroup
                      onChangeActiveIndex={this.handleTabChange}
                      activeIndex={activeTabIndex}
                    >
                      <ELSTab title={messages.QUESTIONS}>
                        <FlexLayout modifiers={[FlexLayoutModifier.MIDDLE, FlexLayoutModifier.WRAP_AT_MOBILE]}>
                          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                            <h3>{messages.HOW_DID_I_PERFORM_ON_THIS_QUIZ}</h3>
                            {this.renderTotalQuestionsText(assessmentResults)}
                          </FlexItem>
                          <FlexItem
                            modifiers={[FlexLayoutModifier.RIGHT]}
                            classes={['u-els-width-1o1@mobile u-els-padding-top@mobile']}
                          >
                            <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
                              <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2]}>
                                <FlexItem modifiers={[FlexLayoutModifier.RIGHT]}>
                                  <p>{messages.VIEWING}</p>
                                </FlexItem>
                                <FlexItem>
                                  <Dropdown
                                    id="c-ssa-filter-dropdown"
                                    options={dropdownOptions}
                                    selected={activeDropdownOption}
                                    showSelectedAsLabel
                                    onChange={(newOptionSelected: DropDownOption) => {
                                      this.setState({ activeDropdownOption: newOptionSelected });
                                    }}
                                    flyoutProps={{
                                      pointer: false,
                                      pointerType: 'primary',
                                      placement: 'bottom-end'
                                    }}
                                  />
                                </FlexItem>
                                <FlexItem modifiers={[FlexLayoutModifier.LEFT]}>
                                  <p>{messages.QUESTIONS.toLowerCase()}</p>
                                </FlexItem>
                              </FlexLayout>
                            </FlexLayout>
                          </FlexItem>
                        </FlexLayout>
                      </ELSTab>
                      <ELSTab title={messages.TOPIC}>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Quisque sed ex ut felis vulputate ultrices at quis mi.
                          Integer venenatis, lectus vel rutrum rutrum, neque justo
                          porta risus, at fringilla diam augue sit amet nunc.
                          Etiam venenatis ornare turpis eget sodales. Praesent
                          laoreet bibendum nisl eu ultricies. Donec non iaculis
                          tortor.Vivamus nisi neque, tempus id turpis nec, sagittis
                          maximus nibh. Praesent ullamcorper, dolor at condimentum
                          sagittis, neque purus vulputate enim, et congue nulla leo
                          eu turpis. Integer pretium risus at enim dictum, vel
                          commodo lectus hendrerit. Donec tempus dui turpis, ac
                          mattis orci volutpat vel. Pellentesque habitant morbi
                          tristique senectus et netus et malesuada fames ac turpis
                          egestas. Aliquam lobortis eleifend orci et faucibus.
                          Fusce hendrerit at est eget feugiat. Proin et
                          ullamcorper lectus. Vestibulum a urna mi. Integer nec
                          nulla hendrerit, egestas sem in, consequat elit. Donec
                          quis sapien commodo, pretium tortor sed, congue
                        </p>
                      </ELSTab>
                    </ELSTabGroup>
                  </div>
                )}

              {assessmentType === AssessmentTypeDto.QUIZ_BY_QUESTION
                && (
                  <div>
                    <h3>CBQ</h3>
                    <p>work in progress</p>
                  </div>
                )}
            </FlexItem>
          </FlexLayout>

        </div>
      </div>
    );
  }
}

const enhancers = [
  withHTMLHeadSEO({ title: constants.eaqStudentStudyPerformanceDetailsPageTitle }),
  injectIntl,
  connector,
  withPageLoader
];

export default compose(...enhancers)(EaqStudentPerformanceDetails);
