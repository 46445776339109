import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Footer } from '@els/els-react--footer';
import { Card } from '@els/els-react--card';
import { ELSButton } from '@els/els-component-button-react';
import {
  injectIntl,
  IntlShape
} from 'react-intl';
import cx from 'classnames';
import {
  get,
  isNil
} from 'lodash';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { locationActions } from '../../redux/location/location.actions';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import * as constants from './eaq-student-study.constants';
import {
  AssessmentTopicsDto,
  StudentStudyDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { ELSDataTable } from '../../components/els.components';
import { AssessmentTypeDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import { AnalyticsAction } from '../../models/analytics.models';
import {
  addSearchParams,
  getLocalMomentInsFromServicesUTC
} from '../../utilities/app.utilities';
import { DATE_SECONDARY } from '../../constants/date.constants';
import { getTimeSpentFormat } from '../eaq-student-topic-report/eaq-student-topic-report.utilities';
import StudentStudyHeaderNavComponent from '../../components/student-study-header-nav/StudentStudyHeaderNav.component';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { RoutePath } from '../../components/app/app.constants';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  studentStudyData: studySelectors.getStudentStudyAssessments(state),
  showPageLoader: studySelectors.getIsLoading(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state),
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  returnAppLink: studyActions.returnAppLink,
  fetchStudentStudyAssessments: studyActions.fetchStudentStudyAssessmentsAction,
  trackAction: studyActions.trackAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type EaqStudentStudyProps = PropsFromRedux & RouteComponentProps & { intl: IntlShape };

export type StudentStudyTableData = {
  topic: string;
  quizType: string;
  goal: string;
  timeSpent: number;
  dateCreated: string;
  score: number;
  dateCompleted: string;
  resume: JSX.Element;
}

export class EaqStudentStudy extends Component<EaqStudentStudyProps> {
  componentDidMount() {
    const { fetchStudentStudyAssessments, courseSectionId } = this.props;
    fetchStudentStudyAssessments(courseSectionId);
  }

  getParentLinkId = (): string => {
    const { appLinkData, appLinkCookies } = this.props;
    if (appLinkData && appLinkData.parentLinkId) {
      return appLinkData.parentLinkId;
    }
    return appLinkCookies ? appLinkCookies.linkId : null;
  }

  handleReturnNavigation = () => {
    this.props.returnAppLink({
      linkId: this.getParentLinkId(),
      returnPostBody: null
    });
  }

  isAssessmentComplete = (entry: StudentStudyDto): boolean => {
    const {
      assessment,
      assessmentSubmission
    } = entry;

    if (assessment.type === AssessmentTypeDto.MASTERY) {
      const currentMastery: number = get(entry, 'topicPerformanceResponseList[0].studentPerformances[0].masteryLevel', null);
      const masteryGoal: number = get(entry, 'assessment.assessmentGoals[0].goal', null);

      return currentMastery >= masteryGoal && Boolean(assessmentSubmission);
    }

    if (!assessmentSubmission) {
      return null;
    }

    const isTypeStandardOrAuthess = (assessment.type === AssessmentTypeDto.STANDARD
      || assessment.type === AssessmentTypeDto.AUTHESS);

    return !!(isTypeStandardOrAuthess && assessmentSubmission);
  }

  handleResumeViewNavigation = (entry: StudentStudyDto) => {
    const {
      featureFlagsGrouped,
      courseSectionId,
      navigateToApp,
      trackAction,
      redirect
    } = this.props;
    const isAssessmentComplete: boolean = this.isAssessmentComplete(entry);
    const action: AnalyticsAction = isAssessmentComplete ? AnalyticsAction.VIEW_RESULTS_CLICK : AnalyticsAction.RESUME_QUIZ_CLICK;
    const isEnabledStudentPerformanceDetails: boolean = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
      featureFlagsGrouped, FEATURE_FLAG.ENABLE_STUDENT_PERFORMANCE_DETAILS, courseSectionId
    );
    const { assessment } = entry;

    trackAction({
      action,
      props: {
        assessmentId: assessment.id,
        assessmentType: assessment.type,
        assessmentGoal: assessment.assessmentGoals[0] ? assessment.assessmentGoals[0].goal : null,
        assessmentTopicVtwIds: assessment.assessmentTopics.map(t => t.vtwId).sort()
      }
    });

    if (assessment.type === AssessmentTypeDto.AUTHESS) {
      navigateToApp({
        action: isAssessmentComplete ? AppAction.ASSESSMENT_PERFORMANCE_VIEW : AppAction.ASSESSMENT_START,
        app: Application.AUTHESS,
        body: { assessmentId: assessment.id },
        altSrcApp: Application.STUDENT_STUDY,
        parentLinkId: this.getParentLinkId(),
        includeLinkHash: true
      });
    } else if (isEnabledStudentPerformanceDetails && isAssessmentComplete) {
      return redirect(addSearchParams(RoutePath.EAQ_STUDENT_PERFORMANCE_DETAILS, { assessmentId: assessment.id }));
    } else {
      navigateToApp({
        action: AppAction.ASSESSMENT_START,
        app: Application.EAQ,
        body: {
          isSelfStudy: true,
          isAssessmentComplete,
          isAuthess: false,
          assessmentId: assessment.id
        },
        altSrcApp: Application.STUDENT_STUDY,
        parentLinkId: this.getParentLinkId(),
        includeLinkHash: true
      });
    }
  }

  createNewQuiz = () => {
    const {
      trackAction,
      redirect,
      featureFlagsGrouped,
      courseSectionId
    } = this.props;

    trackAction({
      action: AnalyticsAction.CREATE_A_QUIZ_CLICK,
      props: {}
    });

    const enableRedirect = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
      featureFlagsGrouped, FEATURE_FLAG.ENABLE_SELF_STUDY_ASSESSMENT_CREATOR_REDIRECT, courseSectionId
    );

    if (enableRedirect) {
      return redirect(RoutePath.ASSESSMENT_START);
    }

    return this.props.navigateToApp(
      {
        action: AppAction.ASSESSMENT_START,
        app: Application.EAQ,
        body: { isSelfStudy: true },
        altSrcApp: Application.STUDENT_STUDY,
        parentLinkId: this.getParentLinkId(),
        includeLinkHash: true
      }
    );
  }

  renderQuizType = (entry: StudentStudyDto): string => {
    const { messages } = this.props;
    const { assessment } = entry;

    if (!entry || !assessment || !assessment.type) {
      return null;
    }
    if (assessment.type === AssessmentTypeDto.MASTERY) {
      return messages.MASTERY;
    }
    if (assessment.type === AssessmentTypeDto.STANDARD) {
      return messages.CUSTOM;
    }
    if (assessment.type === AssessmentTypeDto.AUTHESS) {
      return messages.CASE_STUDY;
    }

    return assessment.type;
  }

  getGoal = (entry: StudentStudyDto): string => {
    const { assessmentGoals, type } = entry.assessment;
    const { masteryLevelToValueMap, masteryLevel } = constants;
    const { messages } = this.props;

    if (!assessmentGoals || !assessmentGoals.length || !assessmentGoals[0].goal) {
      return null;
    }

    if (type === AssessmentTypeDto.STANDARD || type === AssessmentTypeDto.AUTHESS) {
      return `${Math.round(assessmentGoals[0].goal)} ${this.props.messages.QUESTIONS}`;
    }

    const roundedGoalValue = Math.round(assessmentGoals[0].goal);

    if (type === AssessmentTypeDto.MASTERY) {
      if (roundedGoalValue <= masteryLevelToValueMap.get(masteryLevel.NOVICE)) {
        return messages.NOVICE;
      }
      if (roundedGoalValue <= masteryLevelToValueMap.get(masteryLevel.INTERMEDIATE)) {
        return messages.INTERMEDIATE;
      }
      if (roundedGoalValue <= masteryLevelToValueMap.get(masteryLevel.PROFICIENT)) {
        return messages.PROFICIENT;
      }
      if (roundedGoalValue > masteryLevelToValueMap.get(masteryLevel.PROFICIENT)) {
        return messages.LIMITLESS;
      }
    }

    return type;
  }

  getTimeSpent = (entry: StudentStudyDto): number => {
    if (!entry.assessmentQuestionList || !entry.assessmentQuestionList.length) {
      return 0;
    }

    const timeSpent: number = entry.assessmentQuestionList
      .reduce((acc, cur) => acc + cur.timeSpent, 0);

    return !timeSpent || entry.assessmentQuestionList.length === 0 ? 0 : timeSpent;
  }

  getTotalNumberOfQuestions = (): number => {
    const { studentStudyData } = this.props;
    return studentStudyData.reduce((acc, cur) => {
      return acc + cur.assessmentQuestionList.length;
    }, 0);
  }

  renderTotalNumberOfQuestionsText = (): JSX.Element => {
    const { intl, messages } = this.props;
    const returnString = intl.formatMessage(
      {
        id: LANGUAGE_KEY.YOUVE_ANSWERED_NUM_SELF_STUDY_QUESTIONS_IN_THIS_COURSE,
        defaultMessage: messages.YOUVE_ANSWERED_NUM_SELF_STUDY_QUESTIONS_IN_THIS_COURSE
      },
      {
        totalNumberOfQuestions: this.getTotalNumberOfQuestions(),
        bold: str => <b>{str}</b>,
      }
    );
    return (
      <div>
        <h2>{returnString}</h2>
      </div>
    );
  }

  getCompletionStatus = (entry: StudentStudyDto): string => {
    const {
      assessment,
      topicPerformanceResponseList,
      assessmentQuestionList
    } = entry;
    const { messages } = this.props;
    const { masteryLevelToValueMap, masteryLevel } = constants;
    const assessmentGoal: number = assessment.assessmentGoals.length !== 0 ? assessment.assessmentGoals[0].goal : null;

    if (!AssessmentTypeDto.AUTHESS
      && (!topicPerformanceResponseList
        || !topicPerformanceResponseList.length)) {
      return '';
    }

    if (assessment.type === AssessmentTypeDto.MASTERY) {
      // * - this logic is flawed -- rounding the goal here is not accurate. Will be fixed as part of AXE-2342
      if (Math.round(assessmentGoal) > masteryLevelToValueMap.get(masteryLevel.PROFICIENT)) {
        return messages.LIMITLESS;
      }
      const currentMastery: number = get(entry, 'topicPerformanceResponseList[0].studentPerformances[0].masteryLevel', 0);
      // * - For mastery, there will only ever be 1 topic in topicPerformanceResponseList
      const percentage: number = (currentMastery / assessmentGoal) * 100;

      let percentageNormalized: number = Math.floor(percentage);
      if (percentageNormalized > 100) {
        percentageNormalized = 100;
      }

      return `${percentageNormalized}% complete`;
    }

    const fraction = `${assessmentQuestionList.length}/${assessmentGoal}`;

    return `${fraction} questions`;
  }

  renderResumeViewButton = (entry: StudentStudyDto): JSX.Element => {
    const { messages } = this.props;
    const isAssessmentComplete: boolean = this.isAssessmentComplete(entry);
    const buttonText: string = isAssessmentComplete ? messages.VIEW_RESULT : messages.RESUME_QUIZ;
    const buttonColor: string = isAssessmentComplete ? '' : 'c-els-button--confirm';

    return (
      <button
        type="button"
        className={cx(`c-els-button c-els-button--small ${buttonColor}`)}
        onClick={() => this.handleResumeViewNavigation(entry)}
      >
        {buttonText}
      </button>
    );
  }

  getScore = (entry: StudentStudyDto): number | null => {
    if (!entry.assessmentSubmission) {
      return null;
    }

    // ! - Manually completed Mastery or autocompleted Mastery where student attempted questions
    if (entry.assessment.type === AssessmentTypeDto.MASTERY && entry.assessmentSubmission.totalAnswered > 0) {
      return entry.assessmentSubmission.totalCorrect / entry.assessmentSubmission.totalAnswered;
    }

    // ! - Autocompleted Mastery where student attempted no questions
    if (entry.assessment.type === AssessmentTypeDto.MASTERY && entry.assessmentSubmission.totalAnswered === 0) {
      return 0;
    }

    return entry.assessmentSubmission.score;
  }

  getTableData = (): StudentStudyTableData[] => {
    const {
      studentStudyData
    }: {
      studentStudyData: StudentStudyDto[];
    } = this.props;

    if (!studentStudyData || !studentStudyData.length) {
      return [];
    }

    return studentStudyData.map((entry: StudentStudyDto) => {
      const { assessmentSubmission } = entry;
      const {
        assessmentTopics,
        createdAt
      }: {
        assessmentTopics: AssessmentTopicsDto[];
        createdAt: string;
      } = entry.assessment;

      const dateCompleted: string = assessmentSubmission ? assessmentSubmission.createdAt : null;
      const topic: string = assessmentTopics && assessmentTopics.length ? assessmentTopics[0].text : '';
      const quizType: string = this.renderQuizType(entry);
      const goal: string = this.getGoal(entry);

      const isRowDataMissing: boolean = !topic && !quizType && !goal;

      if (!isRowDataMissing) {
        return {
          topic,
          quizType,
          completionStatus: this.getCompletionStatus(entry),
          goal,
          score: this.getScore(entry),
          timeSpent: this.getTimeSpent(entry),
          dateCreated: createdAt,
          dateCompleted,
          resume: this.renderResumeViewButton(entry)
        };
      }
      return null;
    }).filter(Boolean);
  }

  renderResumeViewHeader = () => {
    const { messages } = this.props;
    return (<div className="u-els-hide-visually">{messages.RESUME_QUIZ_OR_VIEW_RESULTS}</div>);
  }

  renderDate = (date: string): JSX.Element => {
    if (!date) {
      return (<>-</>);
    }

    return (
      <>
        {getLocalMomentInsFromServicesUTC(date).format(DATE_SECONDARY)}
      </>
    );
  }

  renderScore = (score: number): JSX.Element => {
    if (isNil(score)) {
      return (<>-</>);
    }

    const percentage = Math.round(score * 100);

    return (
      <>
        {`${percentage}%`}
      </>
    );
  }

  handleTimeSpentSort = (a: StudentStudyTableData, b: StudentStudyTableData) => {
    const entryA = a.timeSpent;
    const entryB = b.timeSpent;

    if (entryA < entryB) {
      return -1;
    }

    if (entryA > entryB) {
      return 1;
    }

    return 0;
  }

  render() {
    const { messages } = this.props;

    // * - Note: Sticky header in table only works if stickyHeader=true AND there's a maxHeight set
    return (
      <div className="c-ssa-student-study-page">
        <StudentStudyHeaderNavComponent
          handleReturnNavigation={this.handleReturnNavigation}
          headerTitle={messages.SELF_STUDY_PROGRESS}
          isMenuItemsEnabled
        />

        <div className="c-ssa-student-study-page__banner-container">
          <FlexLayout modifiers={[FlexLayoutModifier.CENTER]}>
            <FlexItem>
              <Card
                type="primary"
                className="c-ssa-student-study-page__total-quizzes-card"
                borderWeight="none"
              >
                {this.renderTotalNumberOfQuestionsText()}
                <h4>{messages.INCREASE_YOUR_CONFIDENCE_FOR_YOUR_UPCOMING_EXAMS_BY_PRACTICING_MORE_QUESTIONS}</h4>
                <ELSButton
                  className="c-ssa-student-study-page__create-new-quiz-button"
                  onClick={this.createNewQuiz}
                >
                  {messages.CREATE_A_NEW_QUIZ}
                </ELSButton>
              </Card>
            </FlexItem>
          </FlexLayout>
        </div>

        <div className="c-ssa-student-study-page__body-container">

          <h2>{messages.SELF_STUDY_PROGRESS}</h2>
          <div className="c-ssa-student-study-page__prompt">
            <h4>{messages.BELOW_IS_A_LIST_OF_EACH_SELF_STUDY_QUIZ_YOU_HAVE_CREATED}</h4>
          </div>

          <ELSDataTable
            data={this.getTableData()}
            noWrap
            defaultSortField="dateCreated"
            id="in-progress-self-study-table"
            defaultSortDirection="desc"
            sortIconSize="1o2"
            stickyHeader
            maxHeight="500px"
          >
            <column field="topic" header={messages.TOPICS} sortable />
            <column field="quizType" header={messages.QUIZ_TYPE} sortable />
            <column field="completionStatus" header={messages.COMPLETION_STATUS} sortable />
            <column field="goal" header={messages.GOAL} sortable />
            <column field="score" header={messages.SCORE} sortable customRender={(row) => this.renderScore(row.score)} />
            <column
              field="timeSpent"
              header={messages.TIME_SPENT}
              sortable
              customSort={this.handleTimeSpentSort}
              customRender={(row) => getTimeSpentFormat(row.timeSpent)}
            />
            <column field="dateCreated" header={messages.DATE_CREATED} sortable customRender={(row) => this.renderDate(row.dateCreated)} />
            <column
              field="dateCompleted"
              header={messages.COMPLETED_DATE}
              sortable
              customRender={(row) => this.renderDate(row.dateCompleted)} />
            <column field="resume" header={this.renderResumeViewHeader()} sticky />
          </ELSDataTable>

        </div>
        <div className="c-ssa-student-study-page__footer-container">
          <Footer
            cookieDescription={messages.COOKIES_ARE_USED_BY_THIS_SITE}
            cookieLinkText={messages.COOKIE_PAGE}
          />
        </div>
      </div>
    );
  }
}

const enhancers = [
  withHTMLHeadSEO({ title: constants.eaqStudentStudyProgressReportPageTitle }),
  injectIntl,
  connector,
  withPageLoader
];

export default compose(...enhancers)(EaqStudentStudy);
