import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSDropDown, } from '@els/els-component-form-field-react';
import { ELSPageLoader } from '@els/els-ui-common-react';
import { ELSButton } from '@els/els-component-button-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { Footer } from '@els/els-react--footer';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import {
  fetchQuestionSets,
  fetchStudentTopicPerformances
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import {
  ELSDataTable,
  ELSIcon
} from '../../components/els.components';
import MasteryChart from './MasteryChart.component';
import {
  convertMasteryScoreToPercentage,
  getDefaultQuestionSetIsbn,
  flattenedTopicReportTopics,
  getTopicOrgOptions,
  handleExplainerClick,
  withPageLoaderRequest
} from './eaq-student-topic-report.utilities';
import { ALL_OPTION } from './eaq-student-topic-report.constants';
import {
  EaqAssessmentConfig,
  TopicPerformanceTableRow
} from './eaq-student-topic-report.models';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { RoutePath } from '../../components/app/app.constants';
import {
  EaqQuestionSetDto,
  EaqStudentTopicPerformanceDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { AnalyticsAction } from '../../models/analytics.models';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { locationActions } from '../../redux/location/location.actions';
import * as constants from '../eaq-student-study/eaq-student-study.constants';
import StudentStudyHeaderNavComponent from '../../components/student-study-header-nav/StudentStudyHeaderNav.component';
import { getGoalBasedOnMasteryLevel } from '../eaq-student-study/eaq-student-study-common-util.utilities';
import { getTopicTableRows } from '../../utilities/eaq-self-study.utilities';

type EaqStudentTopicReportPropsOnly = {}

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction
};
const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
  messages: studySelectors.getMessages(state),
  userId: studySelectors.getUserId(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  appLinkData: studySelectors.getLinkData(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type EaqStudentTopicReportProps = PropsFromRedux & EaqStudentTopicReportPropsOnly & ELSPropsFromModalService;

export type EaqStudentTopicReportState = {
  showPageLoader: boolean;
  questionSets: EaqQuestionSetDto[];
  topicPerformances: EaqStudentTopicPerformanceDto[];
  activeQuestionSetIsbn: string;
  activeTopicOrgVtwId: string;
  pendingRequestCount: 0;
}

const defaultState: EaqStudentTopicReportState = {
  showPageLoader: false,
  questionSets: null,
  topicPerformances: null,
  activeQuestionSetIsbn: null,
  activeTopicOrgVtwId: null,
  pendingRequestCount: 0
};

const getParentLinkId = (props: EaqStudentTopicReportProps): string => {
  const { appLinkData, appLinkCookies } = props;
  if (appLinkData && appLinkData.parentLinkId) {
    return appLinkData.parentLinkId;
  }
  return appLinkCookies ? appLinkCookies.linkId : null;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EaqStudentTopicReportComponent = (props: EaqStudentTopicReportProps) => {

  const [state, setState] = useState<EaqStudentTopicReportState>(defaultState);

  const mergeState = (newState: Partial<EaqStudentTopicReportState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const handleQuestionSetChange = (isbn: string) => {
    mergeState({
      activeQuestionSetIsbn: isbn,
      topicPerformances: null
    });
    return withPageLoaderRequest(fetchStudentTopicPerformances(isbn), setState).then((topicPerformances) => {
      mergeState({
        topicPerformances,
        activeTopicOrgVtwId: ALL_OPTION.value as string
      });
    });
  };

  const handleTopicOrgChange = (vtwId: string) => {
    mergeState({ activeTopicOrgVtwId: vtwId });
  };

  useEffect(() => {
    const {
      userId,
      courseSectionId,
    } = props;
    withPageLoaderRequest(fetchQuestionSets(userId, courseSectionId), mergeState)
      .then((questionSets) => {
        const activeQuestionSetIsbn = getDefaultQuestionSetIsbn(questionSets);
        mergeState({ questionSets, activeQuestionSetIsbn });
        return handleQuestionSetChange(activeQuestionSetIsbn);
      });
  }, []);

  const handleReturnNavigation = () => {
    props.returnAppLink({
      linkId: getParentLinkId(props),
      returnPostBody: null
    });
  };

  const handleQuizMeClick = (topic: EaqStudentTopicPerformanceDto) => {
    const {
      vtwId,
      text
    } = topic;
    const masteryLevel = topic.studentPerformances[0] ? topic.studentPerformances[0].masteryLevel : null;
    const goal = getGoalBasedOnMasteryLevel(masteryLevel);
    const assessmentConfig: EaqAssessmentConfig = {
      assessmentGoals: [{ goal, vtwId, text }],
      assessmentTopics: [{ vtwId, text }],
      isbn: state.activeQuestionSetIsbn
    };

    props.trackAction({
      action: AnalyticsAction.QUIZ_ME_ON_THIS_TOPIC_CLICK,
      props: {
        assessmentTopicVtwId: vtwId,
        selectedIsbn: state.activeQuestionSetIsbn
      }
    });

    props.navigateToApp(
      {
        action: AppAction.ASSESSMENT_START,
        app: Application.EAQ,
        body: {
          isSelfStudy: true,
          assessmentConfig,
          ref: RoutePath.EAQ_STUDENT_TOPIC_REPORT
        },
        altSrcApp: Application.STUDENT_STUDY,
        parentLinkId: getParentLinkId(props)
      }
    );
  };

  const flattenedTopics = flattenedTopicReportTopics(state.topicPerformances, state.activeTopicOrgVtwId);
  const tableRows = getTopicTableRows(flattenedTopics);

  const {
    messages,
    modalService,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const bannerImgSrc = require('../../assets/background-hero-performance-mastery.png');

  return (
    <div className="c-ssa-eaq-student-topic-report">
      {state.pendingRequestCount > 0 && (<ELSPageLoader />)}
      <StudentStudyHeaderNavComponent
        handleReturnNavigation={handleReturnNavigation}
        headerTitle={messages.SELF_STUDY_PROGRESS}
        isMenuItemsEnabled
      />

      <div className="c-ssa-eaq-student-topic-report__banner">
        <FlexLayout modifiers={[
          FlexLayoutModifier.GUTTERS,
          FlexLayoutModifier.MIDDLE,
          FlexLayoutModifier.CENTER
        ]}>
          <FlexItem>
            <img
              src={bannerImgSrc}
              alt="banner graphic"
              className="c-ssa-eaq-student-topic-report__banner-img"
            />

          </FlexItem>
          <FlexItem>
            <div className="c-ssa-eaq-student-topic-report__banner-txt">
              {messages.KEEP_UP_THE_GREAT_WORK}
            </div>
          </FlexItem>
        </FlexLayout>
      </div>

      <div className="u-els-padding-top-2x u-els-padding-right-2x u-els-padding-left-2x">
        <div className="c-ssa-eaq-student-topic-report__page-title">
          <h2>{messages.TOPIC_PERFORMANCE}</h2>
        </div>
        <div>
          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.WRAP_AT_MOBILE]}>
            <FlexItem
              modifiers={[FlexLayoutModifier.GROW]}>
              {state.questionSets
                && (
                  <>
                    <h3>{messages.QUESTION_SET}</h3>
                    <div className="o-els-container">
                      <ELSDropDown
                        value={state.activeQuestionSetIsbn}
                        options={state.questionSets.map((item) => {
                          return {
                            value: item.isbn,
                            name: item.title
                          };
                        })}
                        isDisabled={false}
                        changeHandler={(e, value) => handleQuestionSetChange(value)}
                      />
                    </div>
                  </>
                )}
            </FlexItem>
            <FlexItem
              modifiers={[FlexLayoutModifier.GROW]}>
              {state.topicPerformances
                && (
                  <>
                    <h3>{messages.TOPIC_ORGANIZATION}</h3>
                    <div className="o-els-container">
                      <ELSDropDown
                        value={state.activeTopicOrgVtwId}
                        options={getTopicOrgOptions(state.topicPerformances)}
                        isDisabled={!state.topicPerformances || !state.topicPerformances.length}
                        changeHandler={(e, value) => handleTopicOrgChange(value)}
                      />
                    </div>
                  </>
                )}
            </FlexItem>
          </FlexLayout>
        </div>

        <div>
          <FlexLayout modifiers={[FlexLayoutModifier.RIGHT]}>
            <FlexItem>
              <button
                type="button"
                className="u-els-debuttonize u-els-anchorize"
                onClick={() => {
                  handleExplainerClick({ messages, modalService });
                }}
              >
                <FlexLayout modifiers={[
                  FlexLayoutModifier.MIDDLE,
                  FlexLayoutModifier.GUTTERS_1o2,
                ]}>
                  <FlexItem>
                    {messages.WHAT_ARE_MASTERY_LEVELS}
                  </FlexItem>
                  <FlexItem>
                    <ELSIcon
                      size="1x1o2"
                      prefix="gizmo"
                      name="information"
                      customClass="u-els-display-block"
                    />
                  </FlexItem>
                </FlexLayout>
              </button>
            </FlexItem>
          </FlexLayout>

        </div>

        <div className="o-els-container o-els-container--2x">

          {tableRows && (
            <ELSDataTable
              data={tableRows}
              noWrap
              defaultSortField="lineageDisplayOrder"
              id="topic-performance-table"
              defaultSortDirection="asc"
              sortIconSize="1o2"
              stickyHeader // * - Note: Sticky header only works if stickyHeader=true AND there's a maxHeight set
              maxHeight="700px"
            >
              <column
                field="lineageDisplay"
                sortField="lineageDisplayOrder"
                header={messages.TOPIC}
                sortable
              />
              <column
                header={messages.MASTERY_LEVEL}
                sortField="masteryLevel"
                sortable
                sticky
                customRender={(item: TopicPerformanceTableRow) => {
                  return (
                    <button
                      type="button"
                      className="u-els-debuttonize"
                      onClick={() => {
                        handleExplainerClick({ messages, modalService });
                      }}
                    >
                      <div className="u-ssa-min-width-2x">
                        <MasteryChart scoreAsPercentage={convertMasteryScoreToPercentage(item.masteryLevel)} />
                      </div>
                    </button>
                  );
                }}
              />
              <column
                field="questionsAnswered"
                header={messages.QUESTIONS_ANSWERED}
                sortable
              />
              <column
                field="questionsCorrect"
                header={messages.QUESTIONS_CORRECT}
                sortable
              />
              <column
                header={messages.CORRECT_PERCENTAGE}
                sortField="percentCorrect"
                sortable
                customRender={(item: TopicPerformanceTableRow) => {
                  return `${item.percentCorrect}%`;
                }}
              />
              <column
                field="questionsConfident"
                header={messages.QUESTIONS_CONFIDENT}
                sortable
              />
              <column
                field="timeSpentDisplay"
                sortField="timeSpent"
                header={messages.TOTAL_TIME}
                sortable
              />
              <column
                header={(<div className="u-els-hide-visually">{messages.QUIZ_ME_ON_THIS_TOPIC}</div>)}
                sticky
                customRender={(item: TopicPerformanceTableRow) => {
                  return (
                    <ELSButton
                      type={ELSButtonType.SECONDARY}
                      size={ELSButtonSize.SMALL}
                      onClick={() => {
                        handleQuizMeClick(item.topic.item);
                      }}
                    >
                      {messages.QUIZ_ME_ON_THIS_TOPIC}
                    </ELSButton>
                  );
                }}
              />
            </ELSDataTable>
          )}
        </div>
      </div>
      <div className="c-ssa-eaq-student-topic-report__footer-container">
        <Footer
          cookieDescription={messages.COOKIES_ARE_USED_BY_THIS_SITE}
          cookieLinkText={messages.COOKIE_PAGE}
        />
      </div>
    </div>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: constants.eaqStudentTopicReportPageTitle }),
  connector,
  ELSWithModalService
];

const EaqStudentTopicReport = compose<null, EaqStudentTopicReportPropsOnly>(...enhancers)(EaqStudentTopicReportComponent);

export default EaqStudentTopicReport;
