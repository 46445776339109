import moment from 'moment';
import { EaqStudentPerformanceDto, EaqStudentTopicPerformanceDto } from '../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { TopicPerformanceTableRow, TopicWithLineage } from '../pages/eaq-student-topic-report/eaq-student-topic-report.models';
import {
  getPercentageAnsweredCorrectly,
  getStudentPerformance,
  getTimeSpentFormat
} from '../pages/eaq-student-topic-report/eaq-student-topic-report.utilities';
import {
  AssessmentTypeDto,
  AssignmentGradeType
} from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { LANGUAGE_KEY } from '../translations/message.constants';

export const getTopicTableRows = (flattenedTopics: TopicWithLineage[]): TopicPerformanceTableRow[] => {
  if (!flattenedTopics || !flattenedTopics.length) {
    return null;
  }

  return flattenedTopics.map((topic: TopicWithLineage): TopicPerformanceTableRow => {
    const studentPerformance: EaqStudentPerformanceDto = getStudentPerformance(topic.item);
    return {
      ...studentPerformance,
      topic,
      topicName: topic.item.displayName || topic.item.text,
      lineageDisplay: topic.lineage
        .map((parent: EaqStudentTopicPerformanceDto) => {
          return parent.displayName || parent.text;
        }).join(' / '),
      lineageDisplayOrder: topic.lineage.map((item: EaqStudentTopicPerformanceDto) => {
        return item.displayOrder + (item.displayName || item.text);
      }).join('.'),
      timeSpentDisplay: getTimeSpentFormat(studentPerformance.timeSpent),
      percentCorrect: getPercentageAnsweredCorrectly(studentPerformance)
    };
  });
};

export const getFormattedAssessmentGoal = (assessmentType: AssessmentTypeDto, assessmentGoal: number, messages: Partial<Record<LANGUAGE_KEY, string>>) => {
  if (assessmentType === AssessmentTypeDto.MASTERY) {
    switch (Math.floor(assessmentGoal)) {
      case 1:
        return messages.NOVICE;
      case 2:
        return messages.INTERMEDIATE;
      case 3:
        return messages.PROFICIENT;
      default:
        return '--';
    }
  }
  return assessmentGoal.toString();
};

export const getFormattedAssignmentGradeType = (
  assessmentGradeType: string,
  assessmentCompleteDate: string,
  assignmentDueDate: string,
  messages: Partial<Record<LANGUAGE_KEY, string>>
) => {
  if (assessmentGradeType === AssignmentGradeType.PASS_FAIL) {
    return moment(assessmentCompleteDate)
      .isSameOrBefore(moment(assignmentDueDate)) ? messages.PASS : messages.FAIL;
  }
  if (assessmentGradeType === AssignmentGradeType.NOT_GRADED) {
    return messages.NOT_GRADED;
  }
  return '';
};

export const getFormattedAssessmentType = (
  assessmentType: string,
  messages: Partial<Record<LANGUAGE_KEY, string>>
) => {
  switch (assessmentType) {
    case AssessmentTypeDto.MASTERY:
      return messages.MASTERY;
    case AssessmentTypeDto.STANDARD:
      return messages.CUSTOM;
    case AssessmentTypeDto.QUIZ_BY_QUESTION:
      return messages.CUSTOM_QUESTIONS;
    default:
      return '--';
  }
};

export const renderFormattedDate = (date: string) => {
  if (!date) {
    return '--';
  }

  return `${moment(date).format('D MMM YYYY')} at ${moment(date).format('h:mm a')}`;
};
